.table > :not(:first-child) {
  border-top: 0;
}

.table {
  border-collapse: separate;
  border-spacing: 0;
}

.table thead {
  border-bottom: 1px solid $gray-500;
}

a.disabled {
  pointer-events: none;
  color: $gray-500;
}

.text-alternative {
  color: $alternative;
}

.modal-90w {
  max-width: 98%;
}

.fallback-grid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.fallback-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #ccc;
  animation: fallback-grid 1.2s linear infinite;
}

.fallback-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}

.fallback-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}

.fallback-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}

.fallback-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}

.fallback-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}

.fallback-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}

.fallback-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}

.fallback-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}

.fallback-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}

@keyframes fallback-grid {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.period-selector {
  &:after {
    display: none;
  }
}

.bg-custom-gradient {
  background: #fff;
  background: linear-gradient(340deg, rgba(72, 199, 65, 0.5) 0%, rgba(10, 137, 255, 0.5) 35%, rgba(0, 212, 255, 0.5) 100%);
  background-size: cover;
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  overflow: auto;
}

.auth-main-content {
  margin-bottom: 64px;
}

body > div#root {
  min-height: 100vh;
}

.nav-link-flex-height {
  height: 100%;
}

.nav-link-flex-height.active {
  height: calc(100% + 1px);
}

.shadow-inner-end {
  box-shadow: inset -8px 0 8px -8px rgba(0, 0, 0, 0.4);
}

.fw-semibold {
  font-weight: 600;
}

.fs-14 {
  font-size: 14px;
}

.table-responsive {
  th:first-child, td:first-child {
    z-index: 1;
    position: sticky;
    left: 0;
    background: white;

    &:after {
      content: "";
      display: block;
      height: 100%;
      width: 8px;
      position: absolute;
      right: -8px;
      top: 0;
      margin: 0;
      padding: 0;
      box-shadow: inset 8px 0 8px -8px rgba(0, 0, 0, 0.4);
    }
  }
}