﻿$primary: rgb(10, 138, 255);
$success: rgb(72, 199, 65);
$alternative: rgb(72, 199, 65);
$danger: rgb(255, 10, 14);
$info: rgb(0, 212, 255);
$zindex-dropdown: 1050 !default;

$min-contrast-ratio: 3 !default;

@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";
@import "./custom.scss";